import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { gsap } from 'gsap';




createApp(App).use(router, store, gsap).mount('#app')
