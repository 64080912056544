<template>
<div class="scroll-outer" style="height: 3030px;">
          <div class="scroll-inner" style="padding-bottom: 257px; position: sticky; top: 0px;">
            <div class="desktop-project-preview-container fade-in apply">
              <div class="preview-nav">
                <button id="button-1" style="background-color: rgba(113, 121, 143, 0.1);"></button>
                <button id="button-2" style="background-color: rgba(113, 121, 143, 0.1);"></button>
                <button id="button-3" style="background-color: rgb(113, 121, 143);"></button>
              </div>
              <div class="desktop-project-preview" id="impressions-project-preview" style="z-index: -1;"> 
                <div class="left-project-preview animate-on-scroll">
                  <div class="landing-project-info">
                    <h2>Mobile App Plugin, Service Design</h2>
                    <h1>Impressions</h1>
                    <p>
                      I worked on a team to develop a digital service startup concept in the form of a plugin for dating apps. The plugin uses location and profile information to recommend events for dates.
                    </p>
                  </div>
                  <a href="projects/impressions.html" id="impressions-full-project">
                    view full project
                  </a>
                </div>
                <div class="right-project-preview animate-on-scroll">
                  <img src="http://bestauros.com/assets/img/work/impressions_5.jpg">
                </div>
              </div>
              <div class="desktop-project-preview" id="portal-project-preview" style="z-index: -1;">
                <div class="left-project-preview">
                  <div class="landing-project-info">
                    <h2>Company Portal, UX Design</h2>
                    <h1>Patent Portal</h1>
                    <p>
                      I designed the information architecture and interface of a client-facing portal for the delivery of patent services and worked alongside lawyers to learn about patent process and workflows in the process.
                    </p>
                  </div>
                  <a href="projects/portal.html" id="portal-full-project">
                    view full project
                  </a>
                </div>
                <div class="right-project-preview">
                  <img src="http://bestauros.com/assets/img/work/impressions_5.jpg">
                </div>
              </div>
              <div class="desktop-project-preview" id="fca-project-preview" style="z-index: 1;">
                <div class="left-project-preview animate">
                  <div class="landing-project-info">
                    <h2>UX Internship</h2>
                    <h1>Fiat Chrysler AI Concept</h1>
                    <p>
                      I researched in the spaces of Artificial Intelligence (AI), Millennials, and Generation Z and created an in-vehicle AI system aimed at combatting mental health in modern users.
                    </p>
                  </div>
                  <a href="projects/fca.html" id="fca-full-project">
                    view full project
                  </a>
                </div>
                <div class="right-project-preview animate">
                  <img src="http://bestauros.com/assets/img/work/impressions_5.jpg">
                </div>
              </div>
            </div>
          </div>
        </div>
</template>

<script>


export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },

  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  /* DESKTOP PROJECT PREVIEW STYLING */
.scroll-inner {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
}

.desktop-project-preview-container {
  width: 100%;
  height: auto;
  position: relative;
}

.desktop-project-preview {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow: hidden;
}

.desktop-project-preview-container .label {
  font-size: 32px;
  /*letter-spacing: 0.01em;*/
  /*text-transform: uppercase;*/
  width: 50%;
  height: 2.5em;
  display: flex;
  /*align-items: center;*/
  /*justify-content: center;*/
  /*background-color: #00071c;*/
  color: rgba(113,121,143, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  font-weight: 400;
  /*border-left: 3px solid rgba(0, 0, 0, 0.1);*/
}

.preview-nav {
  display: flex;
  position: absolute;
  width: 50%;
  box-sizing: border-box;
  height: auto;
  bottom: 16px;
  right: 50%;
  z-index: 2;
  padding: 0px 24px;
}

.preview-nav button {
  width: calc(100% / 3 - 8px);
  height: 5px;
  background: rgba(113,121,143, 0.1);
  margin: 0px 16px 0px 0px;
  border-radius: none;
  border: none;
  box-sizing: border-box;
}

#button-1 {
  background: rgba(113,121,143, 0.5);
}

#button-3 {
  margin: 0px;
}

#portal-project-preview {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  /*display: none;*/
}

#fca-project-preview {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  /*display: none;*/
}

.left-project-preview {
  width: 100%;
  height: auto;
  background: rgba(113, 121, 143, 0.03);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.landing-project-info {
  box-sizing: border-box;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 40px 11.111% 24px 11.111%;
}

.landing-project-info h1 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 8px;
}

.landing-project-info h2 {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #71798f;
  margin-bottom: 12px;
}

.landing-project-info p {
  font-size: 16px;
  line-height: 1.5em;
}

.left-project-preview a {
  margin: 0px 0px 11.111% 11.111%;
  border: none;
  border-radius: 20px;
  background-color: none;
  width: 140px;
  height: 2em;
  font-size: 14px;
  box-sizing: border-box;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

#impressions-full-project {
  border: 1px solid #EADBF1;
  color: #B47FCD;
}

#impressions-full-project:hover {
  background-color: #B47FCD;
  color: white;
  border: none;
}

#portal-full-project {
  border: 1px solid rgb(183, 198, 211);
  color: #26456D;
}

#portal-full-project:hover {
  background-color: #26456D;
  color: white;
  border: none;
}

#fca-full-project {
  border: 1px solid #B9DEB1;
  color: #57A646;
}

#fca-full-project:hover {
  background-color: #57A646;
  color: white;
  border: none;
}

.right-project-preview {
  width: 100%;
  height: 100%;
  position: relative;
  line-height: 0px;
}

.right-project-preview img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*(for animation)*/
#impressions-project-preview .left-project-preview.animate-on-scroll{
  transform: translateX(-100%);
  opacity: 0;
  transition: all 0.75s ease-in-out;
}

#impressions-project-preview .right-project-preview.animate-on-scroll{
  transform: translateY(100%);
  opacity: 0;
  transition: all 0.75s ease-in-out;
}

#impressions-project-preview .left-project-preview.animate-on-scroll.animate {
  transform: translateX(0);
  opacity: 1;
}

#impressions-project-preview .right-project-preview.animate-on-scroll.animate {
  transform: translateY(0);
  opacity: 1;
}

#portal-project-preview .left-project-preview{
  transform: translateX(-100%);
  opacity: 0;
  transition: all 0.75s ease-in-out;
}

#portal-project-preview .right-project-preview {
  transform: translateY(100%);
  opacity: 0;
  transition: all 0.75s ease-in-out;
}

#portal-project-preview .left-project-preview.animate {
  transform: translateX(0);
  opacity: 1;
}

#portal-project-preview .right-project-preview.animate {
  transform: translateY(0);
  opacity: 1;
}

#fca-project-preview .left-project-preview{
  transform: translateX(-100%);
  opacity: 0;
  transition: all 0.75s ease-in-out;
}

#fca-project-preview .right-project-preview {
  transform: translateY(100%);
  opacity: 0;
  transition: all 0.75s ease-in-out;
}

#fca-project-preview .left-project-preview.animate {
  transform: translateX(0);
  opacity: 1;
}

#fca-project-preview .right-project-preview.animate {
  transform: translateY(0);
  opacity: 1;
}

@media (min-width: 1920px) {
  .landing-project-info h1 {
    font-size: 32px;
  }

  .landing-project-info h2 {
    font-size: 16px;
  }

  .landing-project-info p {
    font-size: 18px;
  }

  .left-project-preview a {
    width: 160px;
    font-size: 16px;
  }
}

@media (max-width: 991px) {
  .scroll-outer {
    display: none;
  }
  .desktop-project-preview-container {
    display: none;
  }
}

</style>
