<template>
  <footer>
      <div class="footer-text">
        <div class="email">
          <h1>Want to talk design?</h1>
          <a href="thomas@bestauros.com">thomas@bestauros.com</a>
        </div>
        <div class="note">
          Website designed and coded by myself.
        </div>
      </div>
    </footer>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    msg: String
  },
}
</script>


<style scoped>
footer {
    width: 100%;
    height: 200px;
    background-color: #00071c;
    box-sizing: border-box;
    padding: 0px 32px;
}

.footer-text {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.email {
    margin: 12px 0px 24px 0px;
    line-height: 24px;
}
footer h1 {
    color: #71798f;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5em;
}
footer a {
    color: white;
    font-weight: 400;
    font-size: 16px;
    text-decoration: none;
    line-height: 1.5em;
}
.note {
    color: #71798f;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5em;
}
@media (min-width: 1920px){
footer {
    box-sizing: border-box;
    padding: 0px 12.5%;
}

footer h1 {
    font-size: 18px;
}

footer a {
    font-size: 18px;
}

.note {
    font-size: 18px;
}
}
</style>