<template>
<div class="home page">
  <div class="intro">
  <div class="intro-content">
    <div class="an_intro_1">
    <h1>THOMAS BESTAUROS</h1>
    </div>
    <div class="an_intro_2">
    <h2>Product + UX Designer from Paris, FR</h2>
    <h2>Previously @ LFprod, JBfilms, Sae</h2>
    </div>
    </div>
    <div id="button">
    <span class="arrow" style="opacity: 0.8;"><a href="#">↓ Scroll</a></span>
    </div>
  </div>
  <div class="first">
    <div class="page-content landing-page-content">
<div class="landing-work-section">
          <div class="subtitle-image will-fade fade-in apply" id="subtitle-image-2">
            <div class="image-background">
              <img src="@/assets/img/landing_middle.png" class="image-bottom" id="image-bottom-2">
              <div class="image-block" id="image-block-2"></div>
            </div>
            <img src="@/assets/img/landing_top.png" class="image-top" id="image-top-2">
          </div>
          <p class="subtitle landing-subtitle">
            <span class="bold-subtitle will-fade fade-in apply">
              Testing the boundaries of user interactions and creating innovative product experiences.
            </span>
            <!-- <span class="small-subtitle will-fade"> 
              Preview my top projects below or check out my work page for a list of all recent projects.
            </span> -->
                   <router-link class="will-fade fade-in apply" to="/work">view all work</router-link>
          </p>
        </div>


        <div class="landing-about-section">
          <div class="subtitle-image will-fade fade-in apply" id="subtitle-image-1">
            <div class="image-background">
              <img src="@/assets/img/landing_middle-02.png" class="image-bottom" id="image-bottom-1">
              <div class="image-block" id="image-block-1"></div>
            </div>
            <img src="@/assets/img/landing_top-02.png" class="image-top" id="image-top-1">
          </div>
          <div class="desktop-subtitle">
            <p class="subtitle landing-subtitle">
              <span class="bold-subtitle will-fade fade-in apply">
                A designer with a passion for user-centered design, a problem-solving approach, and a strong growth mindset.
              </span>
                      <router-link class="will-fade fade-in apply" to="/about" >more about me</router-link>
            </p>
          </div>
        </div>
        </div>
  </div>
   <Footer />
</div>
</template>
<style scoped>


.intro {
    background: #1A283C;
}
.intro h1 {
    line-height: 1.5em;
    color: #71798f;
    font-weight: 400;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    font-size: 15px;
    margin: 0;
}
.intro h2 {
    color: #fff;
    line-height: 1.5em;
    font-weight: 700;
    font-size: 26px;
    margin: 0;
}
.first {
      padding:0;
      padding-top: 90px;
}
.landing-page-content {
    padding-bottom: 170px;
}

.page-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding: 0 12.5% 5vh 12.5%;
}
.fade-in {
    opacity: 0;
    transform: translateY(20px);
    transition: transform 0.75s ease-in-out, opacity 0.75s ease-in-out;
}
.fade-in.apply {
    opacity: 1;
    transform: translateY(0px);
}

.subtitle-image {
    width: 100%;
    box-sizing: border-box;
    height: auto;
    display: flex;
    margin-bottom: auto;
    padding-right: 11.111%;
        position: relative;
}
.image-background {
    width: 100%;
    height: 100%;
    position: relative;
}

.image-bottom {
    width: 100%;
    height: auto;
    vertical-align: top;
    box-sizing: border-box;
    margin: 0px;
}
#image-bottom-2 {
    width: calc(100% - 32px);
    margin: 0px 16px;
}
.image-block {
    background: rgba(9, 58, 206, 0.1);
    position: absolute;
    width: 100%;
    height: auto;
    bottom: 0;
    top: 0;
    right: 0;
    z-index: -1;
    box-sizing: border-box;
    margin: 0px;
}
#image-block-2 {
    background: rgba(166, 216, 212, 0.3);
}
.image-top {
    width: 88.889%;
    height: auto;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    box-sizing: border-box;
}
#image-top-2 {
    width: calc(88.889% - 32px);
    margin: 0px calc(11.111% + 16px) 0px 16px;
}

.landing-subtitle {
    margin-bottom: 0px;
}

.subtitle {
    display: block;
    font-size: 24px;
    line-height: 1.5em;
    box-sizing: border-box;
    margin: 0 0 100px 0;
}

.subtitle a {
    width: 140px;
    height: 2em;
    font-size: 14px;
    font-weight: 400;
    border-radius: 20px;
    background-color: white;
    border: 1px solid rgba(113, 121, 143, 0.4);
    color: #71798f;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
}
.landing-about-section {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.landing-work-section {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.subtitle-image {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    margin-bottom: 42px;
}


@media (min-width: 1920px){
.landing-about-section {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    margin-top: 80px;
}

.landing-work-section {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    margin-bottom: 40px;
}
.subtitle a:hover {
    background-color: #71798f;
    color: white;
    border: none;
}
.first {
      padding: 80px 12.5% 100px 12.5%;
      padding-top: 90px;
}
}
@media (min-width: 992px) and (max-width: 2919px){
.intro h1 {
    line-height: 1.5em;
    color: #71798f;
    font-weight: 400;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    font-size: 18px;
    margin: 0;
}
  .intro h2 {
    color: #fff;
    line-height: 1.5em;
    font-weight: 700;
    font-size: 36px;
    margin: 0;
}
  .first {
      padding: 80px 12.5% 100px 12.5%;
      padding-top: 90px;
}
.subtitle {
  display: block;
    font-size: 36px;
    line-height: 1.5em;
    box-sizing: border-box;
    margin: 0px 0px 100px 0px;
}
.page-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding: 90px 32px 60px 32px;
}
.landing-about-section {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    margin-top: 40px;
}
.landing-work-section {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    margin-bottom: 40px;
}
.subtitle-image {
    width: 100%;
    box-sizing: border-box;
    height: auto;
    display: flex;
    margin-bottom: auto;
    padding-right: 11.111%;
}
.landing-about-section .subtitle-image {
    display: flex;
}
#image-top-1 {
    margin: 0px 0px 0px 11.111%;
}
}




</style>

<script>



  import { gsap } from 'gsap';
  import HelloWorld from '@/components/HelloWorld.vue';
  import Footer from '@/components/Footer.vue';
  export default {

      name: 'App',
  components: {
    HelloWorld,
    Footer
  },


  mounted: function(){
    this.startAnimations()
    },
    methods: {
    startAnimations: function(){;
     gsap.from(".an_intro_1", {opacity: 0, y: 70,webkitFilter: 'blur(6px)',filter: 'blur(6px)'})
     gsap.from(".an_intro_2", {delay: 0.5,opacity: 0, y: 70,webkitFilter: 'blur(6px)',filter: 'blur(6px)'})
     gsap.from("#button", {delay: 1,opacity: 0, y: -70,webkitFilter: 'blur(6px)',filter: 'blur(6px)'})
  }
}

}


</script>
