<template>
<div id="app">
    <div id="navigation">
    <div id="nav-left">
     <router-link class="navLiOverlay" to="/" > <h1>TBestauros</h1></router-link>
    </div>
    <div id="nav-right">
      <nav>
      <div id="navLink">
        <router-link class="navLi" to="/">Home</router-link>
        <router-link class="navLi" to="/about">About</router-link>
        <router-link class="navLi" to="/work">Work</router-link>
        <router-link class="navLi" to="/contact">Contact</router-link>
      </div>
      </nav>
      <div class="header-menu-toggle" height="50px" v-on:click="toggleMenu">
      <svg class="hamburger" viewBox="0 0 100 100" width="50px">
        <line class="line one" x1="25" y1="48" x2="65" y2="48" fill="none" stroke="#000" data-svg-origin="45 42" transform="matrix(1,0,0,1,0,0)" style="transform-origin: 0px 0px"></line>
        <line class="line three" x1="25" y1="60" x2="65" y2="60" fill="none" stroke="#000" data-svg-origin="45 66" transform="matrix(1,0,0,1,0,0)" style="transform-origin: 0px 0px"></line>
      </svg>
      </div>
    </div>
  </div>
<div class="menu" ref="menu">
  <div class="container">
  <div class="menu-main">
    <div class="menu-img" ref="menuc">
        
           </div>
    <div class="row">
      <div class="col col-33 menu-menu" ref="menua" @mouseenter="startRotate" @mouseleave="stopRotate">
          <h3 style="color: #fff">Menu</h3>
          <router-link class="navLiOverlay" to="/" ><h1 ref="menuli1" v-on:click="toggleMenu"  >Home</h1></router-link>
          <router-link class="navLiOverlay" to="/about"><h1  ref="menuli2" v-on:click="toggleMenu" >About</h1></router-link>
          <router-link class="navLiOverlay" to="/work"><h1  ref="menuli3" v-on:click="toggleMenu"  >Work</h1></router-link>
          <router-link class="navLiOverlay" to="/contact"><h1  ref="menuli4" v-on:click="toggleMenu">Contact</h1></router-link>
      </div>
      <div class="col col-33 menu-supplements" ref="menub">
        <h3 style="color: #fff">Get in touch</h3>
        <a>thomas@bestauros.com</a>
       
      </div>
    </div>
  </div>
  </div>
</div> 

  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>

  </div>
</template>
<script>
import { gsap, TimelineLite, Expo, CSSRulePlugin, CSSPlugin, ScrollTrigger } from "gsap";

export default {


  data: function () {
    return {

    };
  },
  mounted: function () {
    const lineOne = document.querySelector(".one");
    const lineThree = document.querySelector(".three");
    const lines = [lineOne, lineThree];
    this.actionNav = gsap.to('nav', {y:'-=60', duration:0.5, ease:'power2.in', paused:true});
    this.tween = gsap
      .timeline({ paused: true })
      .to(lineOne, { y: "6px", ease: Expo.easeInOut },"<")
      .to(lineThree, { y: "-6px", ease: Expo.easeInOut }, "<")
      .to(".navLi", { duration: 0.2, opacity: 0, ease: Expo.easeInOutt }, "<")
      .to(lineOne, {rotation: 45,transformOrigin: "50% 50%",ease: Expo.easeInOut,}, "<")
      .to(lineThree, { rotation: -45, transformOrigin: "50% 50%", ease: Expo.easeInOut },"<")
      .to(this.$refs.menu, {duration: 0.5, top: 0, ease: Expo.easeInOut },"<")
      .from(this.$refs.menua, {duration: 0.8, y:'4px', opacity:0, ease: Expo.easeInOut },"-=0.2")
      .from(this.$refs.menub, {duration: 0.8, y:'4px', opacity:0, ease: Expo.easeInOut },"<")
       .to(this.$refs.menuc, {duration: 0.2, opacity:1, ease: Expo.easeInOut, yoyo:true },"<")  
      .reverse();
  },
  methods: {

    toggleMenu: function () {
      this.tween.reversed(!this.tween.reversed());
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Karla:wght@500;700&display=swap");
body {
  margin: 0;
  font-family: "Karla", sans-serif;
  background: #fff;
}

h1 {
  margin: 0px;
}
.blura {
  opacity: 0;
filter: blur(5px);
    transform: translate(0px, 15px);

}


h3 {
      color: rgb(255, 255, 255);
    font-size: .875rem;
    font-weight: 500;
    /* color: var(--text-opaque); */
    margin-bottom: 2.25rem;
}
svg {
  cursor: pointer;
}
.first {
  background: #fff;
}
.col {
    padding: 0 45px;
    box-sizing: border-box;
    --col-size: 100%;
    width: var(--col-size);
    flex: 0 0 var(--col-size);
}

.col-33 {
    --col-size: 33.33%;
}

@media all and (max-width: 1024px) {
.col-33 {
    --col-size: 100%;
}
}
.row {

    display: flex;
    flex-wrap: wrap;
    flex: auto;
}

.menu {
    z-index: 1;
    position: fixed;
    background: rgb(43 57 88);
    height: 100vh;
    width: 100%;
    top: 100vh;
}

.menu .container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    min-height: 100vh;
    height: auto;
    max-width: 1320px;
    margin: 0 auto;
    padding: 0 6.5rem;
    box-sizing: border-box;
    width: 100%;
}

@media all and (max-width: 1024px) {
.menu .container {

    padding: 0;
}
}
.menu-main {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    position: relative;
    padding: 2rem 0;
}
.menu-main .row {
    flex: 0 0 auto;
}


.menu-img {
  opacity: 0;
    position: fixed;
    /* top: 0; */
    right: 0;
    width: 30%;
    height: 100vh;
    -o-object-fit: cover;
    object-fit: cover;
    transition: all .5s;
    transition-delay: 0;
}
.menu-imgb {
display: none;
    position: fixed;
    /* top: 0; */
    right: 0;
    width: 30%;
    height: 100vh;
    -o-object-fit: cover;
    object-fit: cover;
    transition: all .5s;
    transition-delay: 0;
}


@media all and (max-width: 1024px) {
.menu-img {
display: none;
}

}
.navLiOverlayimg{
  opacity: 0;
}

.line {
  stroke-width: 5;
  stroke: #71798f;
}

.hamburger {
  z-index: 1;
padding-left: 5px;
}

.header-menu-toggle {
  position: absolute;
    top: 0;
    right: 0;
    z-index: 11;
    padding: 17px;
    cursor: pointer;
}
.header-menu-toggle:before {
    width: 44px;
    height: 44px;
    background: hsl(235deg 67% 56% / 10%);
    opacity: 1;
    content: "";
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.header-menu-toggle:hover .line {
  stroke: #fff;
}

#app {
  position: relative;
}

#navigation {
width: 100%;
    height: 76px;
    position: fixed;
    z-index: 2;
    border-bottom-color: var(--text-transparent);
    background: var(--background-opaque);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
}
#nav-left {
  width: 50%;
  float: left;
}
#nav-left h1 {
  text-decoration: none;
  font-weight: 700;
  color: #71798f;
  font-size: 20px;
  padding: 30px;
  margin: 0;
}

#nav-right {
  float: right;
}
#navLink {
  padding: 30px;
  float: left;
      padding-right: 90px;
}

@media all and (max-width: 1024px) {
  #navLink {
    display: none;
  }
}
.navLi {
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
}
.navLiOverlay {
  font-size: 42px !important;
  font-weight: 700 !important;
  display: block !important;
  text-transform: capitalize !important;
      text-decoration: none;
          color: ivory;
}


.menu-main h1 {
    font-size: 2.5rem;
    line-height: normal;
    position: relative;
        margin: 0;
}

.menu-supplements a {
    font-size: 1.5rem;
    line-height: 3rem;
     color:rgb(255, 255, 255);
    display: inline-block;
}

.menuOverlayIn {
  padding: 20%;
}
#navLink-overlay-right img {
object-fit: cover;
    width: 100%;
    height: 100%;
      -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}


#navLink-overlay {
  background: #000014;
  bottom: 0;
  left: 0;
  height: 100vh;
  position: fixed;
  right: 0;
  top: -100vh;
  width: 33.33%;
  z-index: -1;
}


@media all and (max-width: 1024px) {
#navLink-overlay {
  background: #000014;
  top: -100vh;
  width: 100vw;
  z-index: -1;
}

h3{
  padding-top: 3.25em;
} 
}
#navLink-overlay-right {
  background: #00000c;
  
  height: 100vh;
  position: fixed;
  right: 0;
  top: -100vh;
  width: 33.33%;
  z-index: -1;
}

@media all and (max-width: 1024px) {
#navLink-overlay-right {
  display: none;
}
}


#navLink-overlay-center {
  background: #00000c;
  
  height: 100vh;
  position: fixed;
  right: 0;
  top: -100vh;
  width: 33.33%;
  z-index: -1;
}
#nav-right a {
  font-weight: bold;
  color: #71798f;
  text-decoration: none;
  font-size: 18px;
}

#nav-right a.router-link-exact-active {
  color: #ffffff;
  background: #1A283C;
}

.intro {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 12.5% 5vh 12.5%;
}
.intro-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 5vh;
  justify-content: center;
  overflow: hidden;
}

#button a {
  color: #71798f;
  text-decoration: none;
  transition: all 0.3s ease;
}
#button a:hover {
  color: #ffffff;
}
.intro h1 {
  line-height: 1.5em;
  color: #71798f;
  font-weight: 400;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  font-size: 18px;
  margin: 0;
}
.intro h2 {
  color: #fff;
  line-height: 1.5em;
  font-weight: 700;
  font-size: 36px;
  margin: 0;
}

.page {
  width: 100%;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-out;
}

#toggle {
  position: relative;
  width: 30px;
  height: 26px;
}

.toggle {
  height: 26px;
  width: 30px;
  float: left;
  padding-top: 25px;
  padding-right: 30px;
}

#toggle input {
  position: absolute;
  top: -3px;
  left: -3px;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}
#toggle input:checked + span {
  background: transparent;
}
#toggle input:checked + span:after {
  transform: rotate(45deg) translate(5px, -8px);
}
#toggle input:checked + span:before {
  transform: rotate(-45deg) translate(1px, 12px);
}
#toggle span {
  position: absolute;
  width: 100%;
  background: #fff;
  margin-top: 10px;
}
#toggle span:before,
#toggle span:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background: #fff;
  transition: 0.3s;
}
#toggle span:after {
  top: 9px;
}


</style>
